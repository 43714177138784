// 受講者メンバーの登録

import api from './api'

const path = '/user/students'

export default ({
  number,
  code,
  organizationId,
  firstName,
  lastName,
  firstNameKana,
  lastNameKana,
  email,
}) => {
  return api({
    method: 'post',
    url: path,
    params: {
      loginName: number,
      groupCode: code,
      organizationId,
      firstName,
      lastName,
      firstNameKana,
      lastNameKana,
      email,
    },
  })
}
