// アクティベーションメールの送信

import api from './api'

const path = '/user/{id}/send'

export default (userId) => {
  const url = path.replace('{id}', userId)
  return api({
    method: 'post',
    url,
  })
}
