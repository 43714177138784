// 受講者向けの参加登録フォーム

<template lang="pug">
el-container.public-join(direction='vertical')

  el-header.header
    p 名古屋市私立幼稚園協会
    h1 受講者向けメンバー登録

  .wrapper(style='margin: 1rem 1rem 0')
    el-steps(align-center, :active='step')
      el-step(title='情報入力')
      el-step(title='メール送信')
      el-step(title='完了')

  el-main.step-0(v-if='step === 0')

    .info
      | すべて必須項目です。
      br
      | 入力完了後「次へ」を押してください。

    el-form.form(
      label-position='top',
      :model='form',
      :rules='rules',
      ref='form'
    )

      el-form-item(
        label='受講者番号',
        prop='number'
      )
        el-input(
          type='tel',
          v-model.trim='form.number',
          placeholder='0000000'
        )

      el-form-item(
        label='グループコード',
        prop='code'
      )
        el-input(
          type='tel'
          v-model.trim='form.code',
          placeholder='000000'
        )

      el-form-item.kindergarten(
        label='園名',
        required
      )
        el-row.row(type='flex', :gutter='20')
          el-col(:span='12')
            el-select.select(
              placeholder='地区を選択',
              v-model='wardId'
            )
              el-option(
                v-for='item in wardList',
                :key='item.value',
                :label='item.name',
                :value='item.value'
              )
          el-col(:span='12')
            el-form-item(
              prop='organizationId'
            )
              el-select.select(
                placeholder='園名を選択',
                v-model='form.organizationId'
              )
                el-option(
                  v-for='item in organizationList',
                  :key='item.id',
                  :label='item.name'
                  :value='item.id'
                )

      el-form-item(
        label='お名前',
        required
      )
        el-row(type='flex', :gutter='20')
          el-col(:span='12')
            el-form-item(
              prop='firstName'
            )
              el-input(
                placeholder='姓',
                v-model.trim='form.firstName'
              )
          el-col(:span='12')
            el-form-item(
              prop='lastName'
            )
              el-input(
                placeholder='名',
                v-model.trim='form.lastName'
              )

      el-form-item(
        label='おなまえ(ひらがな)',
        required
      )
        el-row(type='flex', :gutter='20')
          el-col(:span='12')
            el-form-item(
              prop='firstNameKana'
            )
              el-input(
                placeholder='せい',
                v-model.trim='form.firstNameKana'
              )
          el-col(:span='12')
            el-form-item(
              prop='lastNameKana'
            )
              el-input(
                placeholder='めい',
                v-model.trim='form.lastNameKana'
              )

      el-form-item(
        label='メールアドレス',
        prop='email'
      )
        el-input(
          v-model.trim='form.email'
        )

    .buttons
      el-button.button(
        type='primary',
        @click='goStep1'
      )
        | 次へ
        i.el-icon-arrow-right.el-icon-right

  el-main.step-1(v-else-if='step === 1')

    p.mail
      | 受講者番号: {{ form.number }}
      br
      | {{ form.email }}

    p.note 受講者番号とメールアドレスが正しいことをご確認の上「メール送信」ボタンを押してください。

    .buttons
      el-button.button(
        type='primary',
        @click='goStep2',
        :loading='sending'
      )
        | メール送信
        i.el-icon-arrow-right.el-icon-right
      br
      br
      el-button.button(
        @click='backStep1'
      ) 修正する

  el-main.step-2(v-else-if='step === 2')
    .description
      span 「{{ form.email }}」
      br
      | にあなたのマイページのアドレスを記載したメールを送信しました。
      | マイページのアドレスを開くことで登録完了となります。
    .buttons
      el-button(
        @click='sendActivationMail',
        :loading='sending'
      ) メールを再送信する
    .hint
      h3 メールが届かない場合
      p
        | メールが届かない場合は、携帯電話会社の迷惑メール設定が有効になっている可能性があります。
        br
        span 「@nagoyashiyo.or.jp」
        | からのメールを受信できるように設定してください。詳細については下記をご参照ください。
        br
        a(href='https://www.nttdocomo.co.jp/info/spam_mail/domain/', target='_blank', rel='noopener') docomoをお使いの方
        br
        a(href='https://www.au.com/support/service/mobile/trouble/mail/email/filter/detail/domain/', target='_blank', rel='noopener') auをお使いの方
        br
        a(href='https://www.softbank.jp/mobile/support/mail/antispam/mms/whiteblack/', target='_blank', rel='noopener') SoftBankをお使いの方
  el-footer
</template>

<script>
import uniqBy from 'lodash.uniqby'
import get from 'lodash.get'

import getOrganizationListApi from '@/api/get-organization-list'
import registerStudentApi from '@/api/register-student'
import sendActivationMailApi from '@/api/send-activation-mail'

export default {
  name: 'PublicJoin',

  data() {
    return {
      form: {
        number: null,
        code: null,
        organizationId: null,
        firstName: null,
        lastName: null,
        firstNameKana: null,
        lastNameKana: null,
        email: null,
      },
      rules: {
        number: [
          { required: true, message: '必須項目です。' },
          { pattern: /^\d+$/, message: '数字で入力してください。' },
        ],
        code: [
          { required: true, message: '必須項目です。' },
          { pattern: /^\d+$/, message: '数字で入力してください。' },
        ],
        organizationId: [{ required: true, message: '必須項目です。' }],
        firstName: [{ required: true, message: '必須項目です。' }],
        lastName: [{ required: true, message: '必須項目です。' }],
        firstNameKana: [
          { required: true, message: '必須項目です。' },
          { pattern: /^[ぁ-ゞー～]+$/, message: 'ひらがなで入力してください。' },
        ],
        lastNameKana: [
          { required: true, message: '必須項目です。' },
          { pattern: /^[ぁ-ゞー～]+$/, message: 'ひらがなで入力してください。' },
        ],
        email: [
          { required: true, message: '必須項目です。' },
          { type: 'email', message: 'メールアドレスを入力してください。' },
          { pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~@-]+$/, message: '入力が正しくありません。' },
        ],
      },
      // 地区のIDは送信しない
      wardId: null,
      // 団体のリスト
      organizations: [],
      // 登録したユーザID
      userId: null,
      // 現在のステップ
      step: 0,
      // 送信中かどうか
      sending: false,
    }
  },

  computed: {
    wardList() {
      return uniqBy(
        this.organizations.map((item) => {
          return {
            name: item.regionName,
            value: item.regionId,
          }
        }),
        'value'
      )
    },
    // 地区を選択した場合は、その地区で絞り込まれる
    organizationList() {
      if (this.wardId) {
        return this.organizations.filter((item) => item.regionId === this.wardId)
      } else {
        return this.organizations
      }
    },
  },

  async created() {
    const response = await getOrganizationListApi()
    if (!response.ok) return
    this.organizations = response.payload.items
  },

  methods: {
    async goStep1() {
      try {
        await this.$refs.form.validate()
        ++this.step
      } catch (e) {
        this.$alert('入力に誤りがあります。', 'エラー', {
          center: true,
          type: 'error',
        })
      }
    },

    async goStep2() {
      const response = await registerStudentApi(this.form)
      if (!response.ok) {
        const errorCode = get(response, 'payload.code')
        let errorMessage = '登録に失敗しました。入力内容をご確認ください。'
        if (errorCode === 'USER_ALREADY_ACTIVATED_ERROR') {
          errorMessage = 'すでに登録済みです。'
        }
        await this.$alert(errorMessage, 'エラー', {
          center: true,
          type: 'error',
        })
        return
      }
      this.userId = response.payload.id
      if (!(await this.sendActivationMail())) return
      ++this.step
    },

    async sendActivationMail() {
      this.sending = true
      // メール送信
      const response2 = await sendActivationMailApi(this.userId)
      if (!response2.ok) {
        await this.$alert('メールの送信に失敗しました。', 'エラー', {
          center: true,
          type: 'error',
        })
        this.sending = false
        return false
      }
      this.sending = false
      return true
    },

    backStep1() {
      --this.step
    },
  },
}
</script>

<style lang="sass" scoped>
.public-join
  .header
    background: $color-1
    color: $white
    line-height: 1
    padding: 0.8rem 0.5rem
    p
      font-size: 0.85rem
    h1
      font-size: 1.1rem
      margin-top: 0.3rem

  .step-0
    .info
      max-width: 500px
      margin: 0 auto
      font-size: 0.85rem
      background: #fffde3
      color: darken($color-1, 20%)
      padding: 0.5rem
      border-radius: 5px

    .form
      max-width: 500px
      margin: 1rem auto 0

      .select
        width: 100%

      +mobile
        .kindergarten
          .row
            display: block
            > *
              float: none
              width: 100%
              &:last-child
                margin-top: 1rem

  .buttons
    margin-top: 3rem
    text-align: center
    .button
      width: 100%
      max-width: 200px

  .step-1
    p
      max-width: 500px
      margin-left: auto
      margin-right: auto
    .mail
      border: 2px solid #8e233b
      background: $white
      padding: 0.5rem
      text-align: center
      font-weight: bold
      border-radius: 5px
    .note
      margin-top: 1rem

  .step-2

    .description
      max-width: 500px
      margin-left: auto
      margin-right: auto
      span
        font-weight: bold

    .hint
      max-width: 500px
      margin-left: auto
      margin-right: auto
      margin-top: 2rem
      border: 1px solid $grey-light
      padding: 0.5rem
      background: $white
      border-radius: 5px
      h3
        font-size: 1rem
        color: #d62a2a
      p
        margin-top: 0.5rem
        font-size: 0.9rem
        line-height: 1.4
        span
          font-weight: bold
        a
          margin-top: 0.5rem
          display: inline-block

    .buttons
      margin-top: 2rem
</style>
