// 団体の一覧を取得

import api from './api'

const path = '/organizations'

export default () => {
  return api({
    url: path,
  })
}
